import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import TabsSection from "@components/orangetabs-section/layout-1";
import BoxSection from "@components/BoxSection/layout-one/layout-2";
import FaqArea from "@containers/faq/layout-03";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import StickyMenu from "@containers/sticky-menu";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";

const Footer = lazy(() => import("@layout/footer/layout-01"))

const SoftwareLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="#1 Software Localization & Translation Services"
        description="Get high quality software localization and translation services. We adapt your programs culturally so you can succeed in new markets. Get a Free Consultation."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["stycky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["page-header-section"]} />
        <QuoteForm />
        {
          isMobile && showRestComponents ? <>
             <PartnerArea data={content["software-logo"]} />
        <FeatureGrid data={content["software-features"]} />
          </> : !isMobile && <>
          <PartnerArea data={content["software-logo"]} />
        <FeatureGrid data={content["software-features"]} />
          </>
        }
        <IntroArea layout={2} data={content["software-type"]} />
        {
          showRestComponents && <>
        <Slider3D data={content["software-localization-applications"]} />
        <BoxSection data={content["software-services-body"]} />
        <IntroAreaReverse data={content["software-global-body"]} />
        <TabsSection data={content["quality-assurance-body"]} />
        <CtaArea data={content["cta-data"]} />
        <IntroArea data={content["software-technology-body"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["softwarefaq-section-two"]} />
        <ContactArea Layout={2} />
        </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query softwareLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "softwarelocal" }, pageType: { eq: "software-page" }) {
      content {
        ...PageContent
      }
    }
  }
`;

SoftwareLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default SoftwareLocalizationPage;
